
import { mapState, mapMutations, mapActions } from "vuex"
export default {
  name: "Default",
  data: () => ({
    is_loaded: false,
    sideBarOpen: false,
    items: [
      {
        icon: "house",
        value: "Home",
        link: "/",
        active: true,
      },
      {
        icon: "receipt",
        value: "Orders",
        link: "/orders",
        active: true,
      },
      {
        icon: "hat-chef",
        value: "Makers",
        link: `/makers`,
        active: true,
      },
      {
        icon: "table-list",
        value: "Maker Categories",
        link: "/maker-categories",
        active: false,
      },
      {
        icon: "utensils",
        value: "Products",
        link: "/products",
        active: true,
      },
      {
        icon: "table-layout",
        value: "Product Categories",
        link: "/product-categories",
        active: true,
      },
      // {
      //   icon: "layer-group",
      //   value: "Bundles",
      //   link: "/bundles",
      //   active: true,
      // },
      {
        icon: "box-open",
        value: "Packages",
        link: "/packages",
        active: false,
      },
      {
        icon: "tag",
        value: "Discounts",
        link: "/discounts",
        active: false,
      },
      {
        icon: "stamp",
        value: "Rewards",
        link: "/rewards",
        active: false,
      },
      {
        icon: "users",
        value: "Customers",
        link: "/customers",
        active: false,
      },
      {
        icon: "people-group",
        value: "Organisations",
        link: "/organisations",
        active: false,
      },
    ],
  }),
  computed: {
    ...mapState(["isAdmin", "errorMessage", "successMessage"]),
  },
  mounted() {
    this.getUser()
    this.setAuthenticated({
      token: this.$cookies.get("auth_token"),
      role: this.$cookies.get("user_role"),
    })
    this.isAdmin && this.items.map((item) => (item.active = true))
    this.is_loaded = true
  },
  methods: {
    ...mapActions(["getUser"]),
    ...mapMutations({
      setAuthenticated: "SET_AUTHENTICATED",
    }),
    toggleSideBar(val) {
      this.sideBarOpen = val
    },
  },
}
