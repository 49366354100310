export const state = () => ({
  allergens: false,
  tags: false,
  attachmentError: false,
  attachmentLoading: false,
  authenticated: false,
  authLoading: false,
  chosenCustomer: false,
  chosenDiscount: false,
  chosenMaker: false,
  chosenBundle: false,
  chosenMakerCategory: false,
  chosenOrder: false,
  chosenPackage: false,
  chosenProduct: false,
  chosenProductCategory: false,
  chosenReward: false,
  chosenUser: false,
  cuisines: false,
  customers: false,
  customersLoading: false,
  discounts: false,
  discountList: false,
  discountsLoading: false,
  errorMessage: false,
  successMessage: false,
  isAdmin: false,
  loading: false,
  loginError: false,
  makers: false,
  makersList: false,
  makerLoading: false,
  makersLoading: false,
  makerCategories: false,
  makerCatgoriesLaoding: false,
  makerReport: false,
  makerReportLoading: false,
  newCustomer: false,
  newDiscount: false,
  newMaker: false,
  newBundle: false,
  newOrder: false,
  newPackage: false,
  newMakerCategory: false,
  newProduct: false,
  newProductCategory: false,
  newReward: false,
  newUser: false,
  orders: false,
  orderLoading: false,
  ordersLoading: false,
  packages: false,
  packagesLoading: false,
  pages: false,
  productCategories: false,
  productCategoriesList: false,
  productCategoriesLoading: false,
  products: false,
  productLoading: false,
  productsLoading: false,
  rewards: false,
  rewardsLoading: false,
  signUpError: false,
  signUpLoading: false,
  themes: false,
  user: false,
  users: false,
  usersLoading: false,
  weekdays: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
  bundlesLoading: false,
  bundles: [],
  seenContractReminder: false,
  organisationError: false,
  organisations: false,
  organisationsLoading: false,
  chosenOrg: false,
  newOrganisation: false,
  createTeamError: false,
  updateTeamError: false,
  removeFromTeamError: false,
  teamLoading: false,
  resendContractLoading: false,
  resendContractError: false,
  updateProductsLoading: false,
  updateProductsError: false,
  createOrderLoading: false,
  regenerateInvoiceLoading: false,
  regenerateInvoiceError: false,
  modifierGroupsLoading: false,
  modifierGroups: false,
  updateFeesLoading: false,
  updateFeesError: false,
  subscriptionLoading: false,
})

export const mutations = {
  SET_AUTH_LOADING(state, payload) {
    state.authLoading = payload
  },
  SET_ERROR_MESSAGE(state, payload) {
    state.errorMessage = payload
  },
  SET_SUCCESS_MESSAGE(state, payload) {
    state.successMessage = payload
  },
  SET_AUTHENTICATED(state, payload) {
    if (payload) {
      this.$cookies.set("auth_token", payload.token, {
        sameSite: true,
        path: "/",
        maxAge: 240 * 60 * 24 * 7,
      })
      this.$cookies.set("user_role", payload.role, {
        sameSite: true,
        path: "/",
        maxAge: 240 * 60 * 24 * 7,
      })
      state.authenticated = true
      state.isAdmin = payload.role === "user"
    } else {
      this.$cookies.remove("auth_token")
      this.$cookies.remove("user_role")
      state.authenticated = false
      state.isAdmin = false
    }
  },
  SET_PAGES(state, payload) {
    state.pages = payload
  },
  SET_CUISINES(state, payload) {
    if (payload.constructor === Array) {
      state.cuisines = payload
    } else {
      state.cuisines.push(payload)
    }
  },
  SET_ALLERGENS(state, payload) {
    if (payload.constructor === Array) {
      state.allergens = payload
    } else {
      state.allergens.push(payload)
    }
  },
  SET_TAGS(state, payload) {
    if (payload.constructor === Array) {
      state.tags = payload
    } else {
      state.tags.push(payload)
    }
  },
  SET_CUSTOMERS_LOADING(state, payload) {
    state.customersLoading = payload
  },
  SET_CUSTOMERS(state, payload) {
    state.customers = payload
  },
  SET_NEW_CUSTOMER(state, payload) {
    state.newCustomer = payload
  },
  SET_CHOSEN_CUSTOMER(state, payload) {
    state.chosenCustomer = payload
  },
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_USERS(state, payload) {
    state.users = payload
  },
  SET_NEW_USER(state, payload) {
    state.newUser = payload
  },
  SET_CHOSEN_USER(state, payload) {
    state.chosenUser = payload
  },
  SET_LOGIN_ERROR(state, payload) {
    state.loginError = payload
  },
  SET_SIGN_UP_ERROR(state, payload) {
    state.signUpError = payload
  },
  SET_SIGN_UP_LOADING(state, payload) {
    state.signUpLoading = payload
  },
  SET_MAKER_REPORT_LOADING(state, payload) {
    state.makerReportLoading = payload
  },
  SET_MAKER_REPORT(state, payload) {
    state.makerReport = payload
  },
  SET_MAKERS_LOADING(state, payload) {
    state.makersLoading = payload
  },
  SET_MAKER_LOADING(state, payload) {
    state.makerLoading = payload
  },
  SET_MAKERS(state, payload) {
    const makerIds = state.user.makers.map((maker) => maker.id)
    if (makerIds.length > 0 && !state.isAdmin) {
      state.makers = payload.filter((maker) => makerIds.includes(maker.id))
    } else {
      state.makers = payload
    }
  },
  SET_MAKERS_LIST(state, payload) {
    if (state.isAdmin) {
      state.makersList = payload
    } else {
      state.makersList = payload.filter((maker) =>
        state.user.makers.some((item) => item.id === maker.id)
      )
    }
  },
  SET_CHOSEN_MAKER(state, payload) {
    state.chosenMaker = {
      ...payload,
      deliveryDays: payload.deliveryDays?.sort((a, b) => {
        const aIndex = state.weekdays.indexOf(a.name)
        const bIndex = state.weekdays.indexOf(b.name)
        return aIndex - bIndex
      }),
    }
  },
  SET_NEW_MAKER(state, payload) {
    state.newMaker = payload
  },
  SET_NEW_SPECIAL_DATE(state, payload) {
    state.chosenMaker?.specialDeliveryDays.push(payload)
  },
  SET_MAKER_CATEGORIES_LOADING(state, payload) {
    state.makerCategoriesLoading = payload
  },
  SET_MAKER_CATEGORIES(state, payload) {
    state.makerCategories = payload
  },
  SET_NEW_MAKER_CATEGORY(state, payload) {
    state.newMakerCategory = payload
  },
  SET_CHOSEN_MAKER_CATEGORY(state, payload) {
    state.chosenMakerCategory = payload
  },
  SET_PRODUCTS_LOADING(state, payload) {
    state.productsLoading = payload
  },
  SET_PRODUCT_LOADING(state, payload) {
    state.productLoading = payload
  },
  SET_PRODUCTS(state, payload) {
    const makerIds = state.user.makers.map((maker) => maker.id)
    if (makerIds.length > 0 && !state.isAdmin) {
      state.products = payload.filter((product) =>
        makerIds.includes(product.maker_id)
      )
    } else {
      state.products = payload
    }
  },
  SET_CHOSEN_PRODUCT(state, payload) {
    state.chosenProduct = payload
  },
  SET_NEW_PRODUCT(state, payload) {
    state.newProduct = payload
  },
  SET_NEW_GROUP(state, payload) {
    state.chosenProduct.modifierGroups.push(payload)
  },
  SET_NEW_MODIFIER(state, payload) {
    state.chosenProduct.modifierGroups[payload.index].modifiers.push(
      payload.body
    )
  },
  SET_PACKAGES_LOADING(state, payload) {
    state.packagesLoading = payload
  },
  SET_CHOSEN_PACKAGE(state, payload) {
    state.chosenPackage = payload
  },
  SET_NEW_PACKAGE(state, payload) {
    state.newPackage = payload
  },
  SET_PACKAGES(state, payload) {
    state.packages = payload
  },
  SET_BUNDLES_LOADING(state, payload) {
    state.bundlesLoading = payload
  },
  SET_CHOSEN_BUNDLE(state, payload) {
    state.chosenBundle = payload
  },
  SET_NEW_BUNDLE(state, payload) {
    state.newBundle = payload
  },
  SET_BUNDLES(state, payload) {
    state.bundles = payload
  },
  SET_PRODUCT_CATEGORIES_LOADING(state, payload) {
    state.productCategoriesLoading = payload
  },
  SET_NEW_PRODUCT_CATEGORIES_SORT(state, payload) {
    const newOrder = []
    payload.forEach((dataItem) => {
      newOrder.push(
        state.chosenMaker.productCategories.find(
          (productCategory) => productCategory.id === dataItem.id
        )
      )
    })
    state.chosenMaker.productCategories = newOrder
  },
  SET_PRODUCT_CATEGORIES(state, payload) {
    state.productCategories = payload
  },
  SET_PRODUCT_CATEGORIES_LIST(state, payload) {
    state.productCategoriesList = payload
  },
  SET_NEW_PRODUCT_CATEGORY(state, payload) {
    state.newProductCategory = payload
  },
  SET_CHOSEN_PRODUCT_CATEGORY(state, payload) {
    state.chosenProductCategory = payload
  },
  SET_DISCOUNTS_LOADING(state, payload) {
    state.discountsLoading = payload
  },
  SET_DISCOUNTS(state, payload) {
    state.discounts = payload
    state.discountList = payload
      .filter((item) => item.is_active)
      .map((discount) => {
        return { id: discount.id, name: discount.code }
      })
  },
  SET_NEW_DISCOUNT(state, payload) {
    state.newDiscount = payload
  },
  SET_CHOSEN_DISCOUNT(state, payload) {
    state.chosenDiscount = payload
  },
  SET_REWARDS_LOADING(state, payload) {
    state.rewardsLoading = payload
  },
  SET_REWARDS(state, payload) {
    state.rewards = payload
  },
  SET_NEW_REWARD(state, payload) {
    state.newReward = payload
  },
  SET_CHOSEN_REWARD(state, payload) {
    state.chosenReward = payload
  },
  SET_ORDER_LOADING(state, payload) {
    state.orderLoading = payload
  },
  SET_ORDERS_LOADING(state, payload) {
    state.ordersLoading = payload
  },
  SET_ORDERS(state, payload) {
    const makerIds = state.user.makers.map((maker) => maker.id)
    if (makerIds.length > 0 && !state.isAdmin) {
      state.orders = payload.filter((order) =>
        makerIds.includes(order.maker_id)
      )
    } else {
      state.orders = payload
    }
  },
  SET_NEW_ORDER(state, payload) {
    state.newOrder = payload
  },
  SET_CHOSEN_ORDER(state, payload) {
    state.chosenOrder = payload
  },
  SET_ATTACHMENT_ERROR(state, payload) {
    state.attachmentError = payload
  },
  SET_ATTACHMENT_LOADING(state, payload) {
    state.attachmentLoading = payload
  },
  SET_ENTITY_STATE(state, payload) {
    const index = state[payload.entity].findIndex(
      (entity) => entity.id === payload.id
    )
    state[payload.entity][index][payload.field] =
      typeof payload.setValue === "boolean"
        ? !state[payload.entity][index][payload.field]
        : payload.setValue
  },
  SET_SEEN_CONTRACT_REMINDER(state, payload) {
    state.seenContractReminder = payload
    this.$cookies.set("seen-contract-reminder", payload, {
      sameSite: true,
      path: "/",
      maxAge: 60 * 60 * 24,
    })
  },
  SET_ORGANISATION_ERROR(state, payload) {
    state.organisationError = payload
  },
  SET_ORGANISATIONS_LOADING(state, payload) {
    state.organisationsLoading = payload
  },
  SET_ORGANISATIONS(state, payload) {
    state.organisations = payload
  },
  SET_CHOSEN_ORGANISATION(state, payload) {
    state.chosenOrg = payload
  },
  SET_NEW_ORGANISATION(state, payload) {
    state.newOrganisation = payload
  },
  SET_CREATE_TEAM_ERROR(state, payload) {
    state.createTeamError = payload
  },
  SET_UPDATE_TEAM_ERROR(state, payload) {
    state.updateTeamError = payload
  },
  SET_REMOVE_FROM_TEAM_ERROR(state, payload) {
    state.removeFromTeamError = payload
  },
  SET_TEAM_LOADING(state, payload) {
    state.teamLoading = payload
  },
  SET_RESEND_CONTRACT_LOADING(state, payload) {
    state.resendContractLoading = payload
  },
  SET_RESEND_CONTRACT_ERROR(state, payload) {
    state.resendContractError = payload
  },
  SET_UPDATE_PRODUCTS_LOADING(state, payload) {
    state.updateProductsLoading = payload
  },
  SET_UPDATE_PRODUCTS_ERROR(state, payload) {
    state.updateProductsError = payload
  },
  SET_CREATE_ORDER_LOADING(state, payload) {
    state.createOrderLoading = payload
  },
  SET_REGENERATE_INVOICE_LOADING(state, payload) {
    state.regenerateInvoiceLoading = payload
  },
  SET_REGENERATE_INVOICE_ERROR(state, payload) {
    state.regenerateInvoiceError = payload
  },
  SET_MODIFIER_GROUPS(state, payload) {
    state.modifierGroups = payload
  },
  SET_MODIFIER_GROUPS_LOADING(state, payload) {
    state.modifierGroupsLoading = payload
  },
  SET_UPDATE_FEES_LOADING(state, payload) {
    state.updateFeesLoading = payload
  },
  SET_UPDATE_FEES_ERROR(state, payload) {
    state.updateFeesError = payload
  },
  SET_SUBSCRIPTION_LOADING(state, payload) {
    state.subscriptionLoading = payload
  },
}

export const actions = {
  async logInUser({ commit, dispatch }, payload) {
    commit("SET_AUTH_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(payload.body)

    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/public/users/login`, body, config)
      .then((response) => {
        commit("SET_AUTH_LOADING", false)
        commit("SET_AUTHENTICATED", response.data)
        dispatch("getUser")
        this.$router.push(payload.redirect)
      })
      .catch((error) => {
        commit("SET_AUTH_LOADING", false)
        if (error.response) {
          const status = error.response.status
          switch (status) {
            case 400:
              commit("SET_LOGIN_ERROR", "Invalid credentials.")
              break
            case 403:
              commit(
                "SET_LOGIN_ERROR",
                "Access forbidden. User account not activated."
              )
              break
            case 404:
              commit("SET_LOGIN_ERROR", "Invalid credentials.")
              break
            case 500:
              commit(
                "SET_LOGIN_ERROR",
                "Internal server error. Please try again later."
              )
              break
            default:
              commit("SET_LOGIN_ERROR", error.message)
              break
          }
        } else {
          commit(
            "SET_LOGIN_ERROR",
            "An unexpected error occurred. Please try again later or contact our service."
          )
        }
        console.log({ error, payload: body }, error.response)
      })
  },
  logoutUser({ commit }) {
    commit("SET_AUTHENTICATED", false)
    this.$router.push("/login")
  },
  async getUsers({ commit }, payload) {
    const filters = payload?.filters || []
    const filterQuery = generateFilterQuery(filters)
    const page = payload?.page || 1
    const limit = payload?.limit || 20
    const query = `?limit=${limit}&page=${page}${filterQuery}`
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users${query}`, config)
      .then((response) => {
        commit("SET_USERS", response.data.users)
        commit("SET_PAGES", response.data.paginate)
        commit("SET_AUTH_LOADING", false)
      })
      .catch((error) => {
        commit("SET_AUTH_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getUser({ commit, dispatch }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/profile`, config)
      .then((response) => {
        commit("SET_USER", response.data)
        response.data.role === "maker" &&
          commit("SET_MAKERS", response.data.makers)
      })
      .catch((error) => {
        dispatch("logoutUser")
        console.log({ error }, error.response)
      })
  },
  async getUserById({ commit }, id) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/${id}`, config)
      .then((response) => {
        commit("SET_CHOSEN_USER", response.data)
      })
      .catch((error) => {
        console.log({ error }, error.response)
      })
  },
  async updateUser({ state, commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(state.chosenUser)
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/${state.chosenUser.id}`,
        body,
        config
      )
      .then(() => {
        commit("SET_SUCCESS_MESSAGE", "Succesfully updated user")
        setTimeout(() => {
          commit("SET_SUCCESS_MESSAGE", false)
        }, 5000)
      })
      .catch((error) => {
        console.log({ error, payload: body }, error.response)
        commit("SET_ERROR_MESSAGE", "Failed to update user")
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  async loginAsUser({ state, commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(state.chosenUser.id)
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/users/loginAsUser/${state.chosenUser.id}`,
        config
      )
      .then((response) => {
        commit("SET_AUTHENTICATED", response.data)
        this.$router.push("/")
      })
      .catch((error) => {
        console.log({ error, payload: body }, error.response)
      })
  },
  async loginAsCustomer({ state }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(state.chosenUser.id)
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/users/loginAsCustomer/${state.chosenCustomer.id}`,
        config
      )
      .then((response) => {
        window.open(
          `${process.env.AND_DINE_STORE}/info?loginAs=${JSON.stringify(
            response.data
          )}`,
          "_blank"
        )
      })
      .catch((error) => {
        console.log({ error, payload: body }, error.response)
      })
  },
  async createUser({ commit }, payload) {
    commit("SET_SIGN_UP_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(payload)
    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/users`, body, config)
      .then((response) => {
        commit("SET_USER", response)
        commit("SET_SIGN_UP_LOADING", false)
        this.$router.push(`/users`)
      })
      .catch((error) => {
        commit("SET_SIGN_UP_LOADING", false)
        commit("SET_SIGN_UP_ERROR", error.response.data)
        console.log({ error, payload: body }, error.response)
      })
  },
  async forgotPassword({ commit }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(payload)
    await this.$axios
      .post(
        `${process.env.AND_DINE_API}/api/public/users/forgotPassword`,
        body,
        config
      )
      .then(() => {
        this.$router.push(`/`)
      })
      .catch((error) => {
        commit("SET_SIGN_UP_ERROR", error.message)
        console.log({ error, payload: body }, error.response)
      })
  },
  async updatePassword({ commit }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(payload)
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/public/users/resetPassword`,
        body,
        config
      )
      .then(() => {
        this.$router.push(`/`)
      })
      .catch((error) => {
        console.log({ error, payload: body }, error.response)
      })
  },
  async deleteUser({ commit }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(payload.body)
    await this.$axios
      .delete(
        `${process.env.AND_DINE_API}/api/users/${payload.id}`,
        body,
        config
      )
      .then((response) => {
        commit("SET_USER", response)
      })
      .catch((error) => {
        console.log({ error, payload: body }, error.response)
      })
  },
  async getCuisines({ commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-customer": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/public/cuisines`, config)
      .then((response) => {
        commit("SET_CUISINES", response.data.models)
      })
      .catch((error) => {
        console.log({ error }, error.response)
      })
  },
  async createCuisines({ commit }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify({ ...payload, is_active: true })
    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/users/cuisines`, body, config)
      .then((response) => {
        commit("SET_CUISINES", response.data.cusines)
      })
      .catch((error) => {
        console.log({ error }, error.response)
      })
  },
  async getAllergens({ commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-customer": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/public/allergens`, config)
      .then((response) => {
        commit("SET_ALLERGENS", response.data.allergens)
      })
      .catch((error) => {
        console.log({ error }, error.response)
      })
  },
  async getTags({ commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-customer": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/public/tags`, config)
      .then((response) => {
        commit("SET_TAGS", response.data.tags)
      })
      .catch((error) => {
        console.log({ error }, error.response)
      })
  },
  async createTag({ commit }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify({ ...payload, is_active: true })
    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/users/tags`, body, config)
      .then((response) => {
        commit("SET_TAGS", response.data)
      })
      .catch((error) => {
        console.log({ error }, error.response)
      })
  },
  async createAllergen({ commit }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify({ ...payload, is_active: true })
    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/users/allergens`, body, config)
      .then((response) => {
        commit("SET_ALLERGENS", response.data)
      })
      .catch((error) => {
        console.log({ error }, error.response)
      })
  },
  async getCustomers({ commit }, payload) {
    const filters = payload?.filters || []
    const filterQuery = generateFilterQuery(filters)
    const page = payload?.page || 1
    const limit = payload?.limit || 20
    const query = `?limit=${limit}&page=${page}${filterQuery}`
    !payload && commit("SET_CUSTOMERS_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/customers${query}`, config)
      .then((response) => {
        commit("SET_CUSTOMERS", response.data.customers)
        commit("SET_PAGES", response.data.paginate)
        commit("SET_CUSTOMERS_LOADING", false)
      })
      .catch((error) => {
        commit("SET_CUSTOMERS_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getCustomerById({ commit }, id) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/customers/${id}`, config)
      .then((response) => {
        commit("SET_CHOSEN_CUSTOMER", response.data)
      })
      .catch((error) => {
        console.log({ error }, error.response)
      })
  },
  async updateCustomer({ state, commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(state.chosenCustomer)
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/customers/${state.chosenCustomer.id}`,
        body,
        config
      )
      .then(() => {
        commit("SET_SUCCESS_MESSAGE", "Succesfully updated customer")
        setTimeout(() => {
          commit("SET_SUCCESS_MESSAGE", false)
        }, 5000)
      })
      .catch((error) => {
        console.log({ error, payload: body }, error.response)
        commit("SET_ERROR_MESSAGE", "Failed to update customer")
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  async getOrders({ commit }, payload) {
    const filters = payload?.filters?.filters || []
    const date = payload?.filters?.date
    const filterQuery = generateFilterQuery(filters, date)
    const page = payload?.page || 1
    const limit = payload?.limit || 20
    let query = `?limit=${limit}&page=${page}${filterQuery}`
    if (payload.orderBy && payload.orderDirection) {
      query += `&orderBy=${payload.orderBy}&orderDirection=${payload.orderDirection}`
    }
    !payload && commit("SET_ORDERS_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/orders${query}`, config)
      .then((response) => {
        commit("SET_ORDERS", response.data.orders)
        commit("SET_PAGES", response.data.paginate)
        commit("SET_ORDERS_LOADING", false)
      })
      .catch((error) => {
        commit("SET_ORDERS_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getOrderById({ commit }, id) {
    commit("SET_ORDER_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/orders/${id}`, config)
      .then((response) => {
        commit("SET_CHOSEN_ORDER", response.data)
        commit("SET_ORDER_LOADING", false)
      })
      .catch((error) => {
        console.log({ error }, error.response)
        commit("SET_ORDER_LOADING", false)
      })
  },
  async updateOrder({ state, commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(state.chosenOrder)
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/orders/${state.chosenOrder.id}`,
        body,
        config
      )
      .then(() => {
        commit("SET_SUCCESS_MESSAGE", "Succesfully updated order")
        setTimeout(() => {
          commit("SET_SUCCESS_MESSAGE", false)
        }, 5000)
      })
      .catch((error) => {
        console.log({ error, payload: body }, error.response.data)
        commit(
          "SET_ERROR_MESSAGE",
          `Failed to update order - ${error.response.data}`
        )
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  async createOrder({ state, commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(state.newOrder)
    commit("SET_CREATE_ORDER_LOADING", true)
    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/users/orders`, body, config)
      .then((response) => {
        commit("SET_SUCCESS_MESSAGE", "Succesfully created order")
        commit("SET_NEW_ORDER", false)
        commit("SET_CREATE_ORDER_LOADING", false)
        setTimeout(() => {
          commit("SET_SUCCESS_MESSAGE", false)
          this.$router.push(`/orders/${response.data.id}`)
        }, 2000)
      })
      .catch((error) => {
        console.log({ error, payload: body }, error.response.data)
        commit("SET_CREATE_ORDER_LOADING", false)
        commit(
          "SET_ERROR_MESSAGE",
          `Failed to update order - ${error.response.data}`
        )
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  async getRewards({ commit }, payload) {
    const filters = payload?.filters || []
    const filterQuery = generateFilterQuery(filters)
    const page = payload?.page || 1
    const limit = payload?.limit || 20
    const query = `?limit=${limit}&page=${page}${filterQuery}`
    !payload && commit("SET_REWARDS_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/rewards${query}`, config)
      .then((response) => {
        commit("SET_REWARDS", response.data.rewards)
        commit("SET_PAGES", response.data.paginate)
        commit("SET_REWARDS_LOADING", false)
      })
      .catch((error) => {
        commit("SET_REWARDS_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getRewardById({ commit }, id) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/rewards/${id}`, config)
      .then((response) => {
        commit("SET_CHOSEN_REWARD", response.data)
      })
      .catch((error) => {
        console.log({ error }, error.response)
      })
  },
  async updateReward({ state, commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(state.chosenReward)
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/rewards/${state.chosenReward.id}`,
        body,
        config
      )
      .then(() => {
        commit("SET_SUCCESS_MESSAGE", "Succesfully updated reward")
        setTimeout(() => {
          commit("SET_SUCCESS_MESSAGE", false)
        }, 5000)
      })
      .catch((error) => {
        console.log({ error, payload: body }, error.response)
        commit("SET_ERROR_MESSAGE", "Failed to update reward")
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  async createReward({ state, dispatch }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(state.newReward)
    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/users/rewards`, body, config)
      .then(() => {
        dispatch("getRewards")
        this.$router.push(`/rewards`)
      })
      .catch((error) => {
        console.log({ error, payload: body }, error.response)
      })
  },
  async deleteReward({ commit }, id) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .delte(`${process.env.AND_DINE_API}/api/users/${id}`, config)
      .then((response) => {
        commit("SET_REWARD", response)
      })
      .catch((error) => {
        console.log({ error }, error.response)
      })
  },
  async getDiscounts({ commit }, payload) {
    const filters = payload?.filters || []
    const filterQuery = generateFilterQuery(filters)
    const page = payload?.page || 1
    const limit = payload?.limit || 999
    const query = `?limit=${limit}&page=${page}${filterQuery}`
    !payload && commit("SET_DISCOUNTS_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/discounts${query}`, config)
      .then((response) => {
        commit("SET_DISCOUNTS", response.data.discounts)
        commit("SET_PAGES", response.data.paginate)
        commit("SET_DISCOUNTS_LOADING", false)
      })
      .catch((error) => {
        commit("SET_DISCOUNTS_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getDiscountById({ commit }, id) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/discounts/${id}`, config)
      .then((response) => {
        commit("SET_CHOSEN_DISCOUNT", response.data)
      })
      .catch((error) => {
        console.log({ error }, error.response)
      })
  },
  async updateDiscount({ state, commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify({
      ...state.chosenDiscount,
      minimum_value: state.chosenDiscount.minimum_value,
    })
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/discounts/${state.chosenDiscount.id}`,
        body,
        config
      )
      .then(() => {
        commit("SET_SUCCESS_MESSAGE", "Succesfully updated discount")
        setTimeout(() => {
          commit("SET_SUCCESS_MESSAGE", false)
        }, 5000)
      })
      .catch((error) => {
        console.log({ error, payload: body }, error.response)
        commit("SET_ERROR_MESSAGE", "Failed to update discount")
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  async createDiscount({ state, dispatch }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify({
      ...state.newDiscount,
      minimum_value: state.newDiscount.minimum_value,
    })
    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/users/discounts`, body, config)
      .then(() => {
        dispatch("getDiscounts")
        this.$router.push(`/discounts`)
      })
      .catch((error) => {
        console.log({ error, payload: body }, error.response)
      })
  },
  async deleteDiscount({ commit }, id) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .delete(`${process.env.AND_DINE_API}/api/users/discounts/${id}`, config)
      .then((response) => {
        commit("SET_DISCOUNTS", response)
      })
      .catch((error) => {
        console.log({ error }, error.response)
      })
  },
  async getMakerCategories({ commit }, payload) {
    const filters = payload?.filters || []
    const filterQuery = generateFilterQuery(filters)
    const page = payload?.page || 1
    const limit = payload?.limit || 20
    const query = `?limit=${limit}&page=${page}${filterQuery}`
    !payload && commit("SET_MAKER_CATEGORIES_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/public/makerCategories${query}`,
        config
      )
      .then((response) => {
        commit("SET_MAKER_CATEGORIES", response.data.models)
        commit("SET_PAGES", response.data.paginate)
        commit("SET_MAKER_CATEGORIES_LOADING", false)
      })
      .catch((error) => {
        commit("SET_MAKER_CATEGORIES_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getMakerCategoryById({ commit }, id) {
    commit("SET_MAKER_CATEGORIES_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/public/makerCategories/${id}`,
        config
      )
      .then((response) => {
        commit("SET_CHOSEN_MAKER_CATEGORY", response.data)
        commit("SET_MAKER_CATEGORIES_LOADING", false)
      })
      .catch((error) => {
        commit("SET_MAKER_CATEGORIES_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async updateMakerCategory({ commit, state }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(state.chosenMakerCategory)
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/makerCategories/${state.chosenMakerCategory.id}`,
        body,
        config
      )
      .then(() => {
        commit("SET_SUCCESS_MESSAGE", "Succesfully updated Maker category")
        setTimeout(() => {
          commit("SET_SUCCESS_MESSAGE", false)
        }, 5000)
      })
      .catch((error) => {
        console.log({ error }, error.response)
        commit("SET_ERROR_MESSAGE", "Failed to update Maker category")
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  async createMakerCategory({ commit }, payload) {
    commit("SET_MAKER_CATEGORIES_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .post(
        `${process.env.AND_DINE_API}/api/users/makerCategories`,
        payload.body,
        config
      )
      .then((response) => {
        commit("SET_MAKER_CATEGORIES", response.data)
        commit("SET_MAKER_CATEGORIES_LOADING", false)
        payload.redirect && this.$router.push(`/maker-categories`)
      })
      .catch((error) => {
        commit("SET_MAKER_CATEGORIES_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getMakers({ commit }, payload) {
    const filters = payload?.filters || []
    const filterQuery = generateFilterQuery(filters)
    const page = payload?.page || 1
    const limit = payload?.limit || 20
    const query = `?limit=${limit}&page=${page}${filterQuery}`
    !payload && commit("SET_MAKERS_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/makers${query}`, config)
      .then((response) => {
        commit("SET_MAKERS", response.data.models)
        commit("SET_PAGES", response.data.paginate)
        commit("SET_MAKERS_LOADING", false)
      })
      .catch((error) => {
        commit("SET_MAKERS_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getMakersList({ commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/makers/list`, config)
      .then((response) => {
        commit("SET_MAKERS_LIST", response.data.models)
        commit("SET_MAKERS_LOADING", false)
      })
      .catch((error) => {
        commit("SET_MAKERS_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getMakerById({ commit }, id) {
    commit("SET_MAKER_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/makers/${id}`, config)
      .then((response) => {
        commit("SET_CHOSEN_MAKER", response.data)
        commit("SET_MAKER_LOADING", false)
      })
      .catch((error) => {
        commit("SET_MAKER_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async createMaker({ state, commit }) {
    commit("SET_MAKER_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify({
      ...state.newMaker,
      free_delivery_min_amount: state.newMaker.free_delivery_min_amount,
      min_order_amount: state.newMaker.min_order_amount,
    })
    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/users/makers`, body, config)
      .then((response) => {
        commit("SET_MAKER_LOADING", false)
        this.$router.push(`/makers/${response.data.id}`)
      })
      .catch((error) => {
        commit("SET_MAKER_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async updateMaker({ state, commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify({
      ...state.chosenMaker,
      free_delivery_min_amount: state.chosenMaker.free_delivery_min_amount,
      min_order_amount: state.chosenMaker.min_order_amount,
    })
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/makers/${state.chosenMaker.id}`,
        body,
        config
      )
      .then(() => {
        commit("SET_SUCCESS_MESSAGE", "Succesfully updated Maker")
        setTimeout(() => {
          commit("SET_SUCCESS_MESSAGE", false)
        }, 5000)
      })
      .catch((error) => {
        console.log({ error }, error.response)
        error.response.status === 400
          ? commit(
              "SET_ERROR_MESSAGE",
              "Please sign the contract to be activated."
            )
          : commit("SET_ERROR_MESSAGE", "Failed to update Maker")
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  async saveProductCategoryOrder({ state }, data) {
    console.error(data)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(data)
    return await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/productCategories/updateProductCategorySort`,
        body,
        config
      )
      .catch((error) => {
        console.log({ error }, error.response)
      })
  },
  async getProductCategories({ commit }, payload) {
    const filters = payload?.filters || []
    const filterQuery = generateFilterQuery(filters)
    const page = payload?.page || 1
    const limit = payload?.limit || 20
    const query = `?limit=${limit}&page=${page}${filterQuery}`
    !payload && commit("SET_PRODUCT_CATEGORIES_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/users/productCategories${query}`,
        config
      )
      .then((response) => {
        commit("SET_PRODUCT_CATEGORIES", response.data.productCategories)
        commit("SET_PAGES", response.data.paginate)
        commit("SET_PRODUCT_CATEGORIES_LOADING", false)
      })
      .catch((error) => {
        commit("SET_PRODUCT_CATEGORIES_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getProductCategoriesList({ commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/users/productCategories/list`,
        config
      )
      .then((response) => {
        commit("SET_PRODUCT_CATEGORIES_LIST", response.data.productCategories)
        commit("SET_PRODUCT_CATEGORIES_LOADING", false)
      })
      .catch((error) => {
        commit("SET_PRODUCT_CATEGORIES_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getProductCategoriesByMaker({ commit }, id) {
    commit("SET_PRODUCT_CATEGORIES_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/users/productCategories/search?maker_id=${id}`,
        config
      )
      .then((response) => {
        commit("SET_PRODUCT_CATEGORIES", response.data.productCategories)
        commit("SET_PRODUCT_CATEGORIES_LOADING", false)
      })
      .catch((error) => {
        commit("SET_PRODUCT_CATEGORIES_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getProductCategoryById({ commit }, id) {
    commit("SET_PRODUCT_CATEGORIES_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/users/productCategories/${id}`,
        config
      )
      .then((response) => {
        commit("SET_CHOSEN_PRODUCT_CATEGORY", response.data)
        commit("SET_PRODUCT_CATEGORIES_LOADING", false)
      })
      .catch((error) => {
        commit("SET_PRODUCT_CATEGORIES_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async updateProductCategory({ commit, state }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }

    const body = JSON.stringify(state.chosenProductCategory)
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/productCategories/${state.chosenProductCategory.id}`,
        body,
        config
      )
      .then(() => {
        commit("SET_SUCCESS_MESSAGE", "Succesfully updated product category")
        setTimeout(() => {
          commit("SET_SUCCESS_MESSAGE", false)
        }, 5000)
      })
      .catch((error) => {
        console.log({ error }, error.response)
        commit("SET_ERROR_MESSAGE", "Failed to update product category")
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  async createProductCategory({ commit }, payload) {
    commit("SET_PRODUCT_CATEGORIES_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify({
      ...payload.body,
      maker_id: payload.body.maker_id.id,
    })
    await this.$axios
      .post(
        `${process.env.AND_DINE_API}/api/users/productCategories`,
        body,
        config
      )
      .then((response) => {
        commit("SET_PRODUCT_CATEGORIES", response.data.productCategories)
        commit("SET_PRODUCT_CATEGORIES_LOADING", false)
        payload.redirect && this.$router.push(`/product-categories`)
      })
      .catch((error) => {
        commit("SET_PRODUCT_CATEGORIES_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getProducts({ commit }, payload) {
    const filters = payload?.filters || []
    const filterQuery = generateFilterQuery(filters)
    const page = payload?.page || 1
    const limit = payload?.limit || 20
    const query = `?limit=${limit}&page=${page}${filterQuery}`
    !payload && commit("SET_PRODUCTS_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/products${query}`, config)
      .then((response) => {
        commit("SET_PRODUCTS", response.data.products)
        commit("SET_PAGES", response.data.paginate)
        commit("SET_PRODUCTS_LOADING", false)
      })
      .catch((error) => {
        commit("SET_PRODUCTS_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async searchProducts({ commit }, id) {
    commit("SET_PRODUCTS_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/users/products/search/?maker_id=${id}`,
        config
      )
      .then((response) => {
        commit("SET_PRODUCTS", response.data.products)
        commit("SET_PAGES", response.data.paginate)
        commit("SET_PRODUCTS_LOADING", false)
      })
      .catch((error) => {
        commit("SET_PRODUCTS_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getProductById({ commit }, id) {
    commit("SET_PRODUCT_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/public/products/${id}`, config)
      .then((response) => {
        commit("SET_CHOSEN_PRODUCT", response.data)
        commit("SET_PRODUCT_LOADING", false)
      })
      .catch((error) => {
        commit("SET_PRODUCT_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async createProduct({ state, dispatch }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify({
      ...state.newProduct,
      price:
        state.newProduct.type === "Daily"
          ? 0
          : !state.chosenMaker.vat_registered ||
            !state.newProduct.vat_level ||
            state.newProduct.vat_level === "0"
          ? state.newProduct.price
          : state.newProduct.price_vat,
    })
    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/users/products`, body, config)
      .then((response) => {
        dispatch("getProducts")

        this.$router.push(`/products/${response.data.id}`)
      })
      .catch((error) => {
        console.log({ error }, error.response)
      })
  },
  async updateProduct({ state, commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify({
      ...state.chosenProduct,
      price:
        state.chosenProduct.type === "Daily"
          ? 0
          : !state.chosenMaker.vat_registered ||
            !state.chosenProduct.vat_level ||
            state.chosenProduct.vat_level === "0"
          ? state.chosenProduct.price
          : state.chosenProduct.price_vat,
      maker_id: state.chosenProduct.maker_id,
      modifierGroups: state.chosenProduct.modifierGroups.map((group) => ({
        ...group,
        modifiers: group.modifiers.map((modifier) => ({
          ...modifier,
          price: parseInt(modifier.price),
        })),
      })),
    })
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/products/${state.chosenProduct.id}`,
        body,
        config
      )
      .then(() => {
        commit("SET_SUCCESS_MESSAGE", "Succesfully updated product")
        setTimeout(() => {
          commit("SET_SUCCESS_MESSAGE", false)
        }, 5000)
      })
      .catch((error) => {
        console.log({ error }, error.response)
        commit("SET_ERROR_MESSAGE", "Failed to update product")
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  async duplicateProduct({ state, commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify({
      ...state.chosenProduct,
    })
    await this.$axios
      .post(
        `${process.env.AND_DINE_API}/api/users/products/clone/${state.chosenProduct.id}`,
        body,
        config
      )
      .then((response) => {
        commit("SET_SUCCESS_MESSAGE", "Succesfully clone product")
        setTimeout(() => {
          commit("SET_SUCCESS_MESSAGE", false)
        }, 5000)
        this.$router.push(`/products/${response.data.id}`)
      })
      .catch((error) => {
        console.log({ error }, error.response)
        commit("SET_ERROR_MESSAGE", "Failed to clone product")
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  async saveAttachment({ commit }, payload) {
    console.error("saveAttachment")
    commit("SET_ATTACHMENT_ERROR", false)
    commit("SET_ATTACHMENT_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-Type": "multipart/form-data",
      },
    }
    await this.$axios
      .post(
        `${process.env.AND_DINE_API}/api/users/attachments/${payload.model}/${payload.type}/${payload.id}`,
        payload.body,
        config
      )
      .then(() => {
        commit("SET_ATTACHMENT_ERROR", false)
        commit("SET_ATTACHMENT_LOADING", false)
        console.log("Attachment saved")
      })
      .catch((error) => {
        console.error(error)
        commit("SET_ATTACHMENT_LOADING", false)
        commit(
          "SET_ATTACHMENT_ERROR",
          "The file is too large. Please reduce image size or upload a smaller one."
        )
        console.log({ error }, error.response)
      })
  },
  async deleteAttachment({ commit }, payload) {
    commit("SET_ATTACHMENT_ERROR", false)
    commit("SET_ATTACHMENT_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-Type": "multipart/form-data",
      },
    }
    await this.$axios
      .delete(
        `${process.env.AND_DINE_API}/api/users/attachments/${payload.id}`,
        config,
        payload.body
      )
      .then(() => {
        commit("SET_ATTACHMENT_ERROR", false)
        commit("SET_ATTACHMENT_LOADING", false)
        console.log("Attachment deleted")
      })
      .catch((error) => {
        commit("SET_ATTACHMENT_ERROR", error.message)
        commit("SET_ATTACHMENT_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async changeEntityStatus({ commit }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(payload)
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/${payload.entity}/changeStatus/${payload.id}`,
        body,
        config
      )
      .then(() => {
        commit("SET_ENTITY_STATE", payload)
        commit("SET_SUCCESS_MESSAGE", "Succesfully updated")
        setTimeout(() => {
          commit("SET_SUCCESS_MESSAGE", false)
        }, 2000)
      })
      .catch((error) => {
        error.response.status === 400
          ? commit(
              "SET_ERROR_MESSAGE",
              error.response.data || "Failed to update"
            )
          : commit("SET_ERROR_MESSAGE", "Failed to update")
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  async getPackages({ commit }, payload) {
    const filters = payload?.filters || []
    const filterQuery = generateFilterQuery(filters)
    const page = payload?.page || 1
    const limit = payload?.limit || 20
    const query = `?limit=${limit}&page=${page}${filterQuery}`
    !payload && commit("SET_PACKAGES_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/packages${query}`, config)
      .then((response) => {
        commit("SET_PACKAGES", response.data.packages)
        commit("SET_PAGES", response.data.paginate)
        commit("SET_PACKAGES_LOADING", false)
      })
      .catch((error) => {
        commit("SET_PACKAGES_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getPackageById({ commit }, id) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/packages/${id}`, config)
      .then((response) => {
        commit("SET_CHOSEN_PACKAGE", response.data)
        commit("SET_PACKAGES_LOADING", false)
      })
      .catch((error) => {
        commit("SET_PACKAGES_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getPackagesByMaker({ commit, state }) {
    commit("SET_PACKAGES_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    if (state.user.makers.length > 0)
      await this.$axios
        .get(
          `${process.env.AND_DINE_API}/api/users/packages/search?maker_id=${state.user.makers[0].id}`,
          config
        )
        .then((response) => {
          commit("SET_PACKAGES", response.data.packages)
          commit("SET_PACKAGES_LOADING", false)
        })
        .catch((error) => {
          commit("SET_PACKAGES_LOADING", false)
          console.log({ error }, error.response)
        })
  },
  async updatePackage({ state, commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify({
      ...state.chosenPackage,
      maker_id: state.chosenPackage.maker_id,
    })
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/packages/${state.chosenPackage.id}`,
        body,
        config
      )
      .then(() => {
        commit("SET_SUCCESS_MESSAGE", "Succesfully updated package")
        setTimeout(() => {
          commit("SET_SUCCESS_MESSAGE", false)
        }, 5000)
      })
      .catch((error) => {
        console.log({ error }, error.response)
        commit("SET_ERROR_MESSAGE", "Failed to update package")
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  async createPackage({ commit }, payload) {
    commit("SET_PACKAGES_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(payload.body)
    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/users/packages`, body, config)
      .then((response) => {
        commit("SET_PACKAGES_LOADING", false)
        this.$router.push(`/packages/${response.data.id}`)
      })
      .catch((error) => {
        commit("SET_PACKAGES_LOADING", false)
        console.log({ error, payload: body }, error.response)
      })
  },
  async getBundles({ commit }, payload) {
    const filters = payload?.filters || []
    const filterQuery = generateFilterQuery(filters)
    const page = payload?.page || 1
    const limit = payload?.limit || 20
    const query = `?limit=${limit}&page=${page}${filterQuery}`
    !payload && commit("SET_BUNDLES_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/bundles${query}`, config)
      .then((response) => {
        commit("SET_BUNDLES", response.data.bundles)
        commit("SET_PAGES", response.data.paginate)
        commit("SET_BUNDLES_LOADING", false)
      })
      .catch((error) => {
        commit("SET_BUNDLES_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getBundlesByMaker({ commit, state }) {
    commit("SET_BUNDLES_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    if (state.user.makers.length > 0)
      await this.$axios
        .get(
          `${process.env.AND_DINE_API}/api/users/bundles/search?maker_id=${state.user.makers[0].id}`,
          config
        )
        .then((response) => {
          commit("SET_BUNDLES", response.data.bundles)
          commit("SET_BUNDLES_LOADING", false)
        })
        .catch((error) => {
          commit("SET_BUNDLES_LOADING", false)
          console.log({ error }, error.response)
        })
  },
  async getBundleById({ commit }, id) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(`${process.env.AND_DINE_API}/api/users/bundles/${id}`, config)
      .then((response) => {
        commit("SET_CHOSEN_BUNDLE", response.data)
        commit("SET_BUNDLES_LOADING", false)
      })
      .catch((error) => {
        commit("SET_BUNDLES_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async updateBundle({ state, commit }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify({
      ...state.chosenBundle,
      maker_id: state.chosenBundle.maker_id,
    })
    await this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/bundles/${state.chosenBundle.id}`,
        body,
        config
      )
      .then(() => {
        commit("SET_SUCCESS_MESSAGE", "Succesfully updated bundle")
        setTimeout(() => {
          commit("SET_SUCCESS_MESSAGE", false)
        }, 5000)
      })
      .catch((error) => {
        console.log({ error }, error.response)
        commit("SET_ERROR_MESSAGE", "Failed to update bundle")
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  async createBundle({ commit }, payload) {
    commit("SET_BUNDLES_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const body = JSON.stringify(payload.body)
    await this.$axios
      .post(`${process.env.AND_DINE_API}/api/users/bundles`, body, config)
      .then((response) => {
        commit("SET_BUNDLES_LOADING", false)
        this.$router.push(`/bundles/${response.data.id}`)
      })
      .catch((error) => {
        commit("SET_BUNDLES_LOADING", false)
        console.log({ error, payload: body }, error.response)
      })
  },
  async getOrganisations({ commit }, payload) {
    const filters = payload?.filters || []
    const filterQuery = generateFilterQuery(filters)
    const page = payload?.page || 1
    const limit = payload?.limit || 20
    const query = `?limit=${limit}&page=${page}${filterQuery}`
    !payload && commit("SET_ORGANISATIONS_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/users/organisations${query}`,
        config
      )
      .then((response) => {
        commit("SET_ORGANISATIONS", response.data.organisations)
        commit("SET_PAGES", response.data.paginate)
        commit("SET_ORGANISATIONS_LOADING", false)
      })
      .catch((error) => {
        commit("SET_ORGANISATIONS_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async getOrganisation({ commit }, payload) {
    !payload && commit("SET_ORGANISATIONS_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/users/organisations/${payload}`,
        config
      )
      .then((response) => {
        commit("SET_CHOSEN_ORGANISATION", response.data)
        commit("SET_ORGANISATIONS_LOADING", false)
      })
      .catch((error) => {
        commit("SET_ORGANISATIONS_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  addOrgMember({ commit, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/organisations/addCustomer/${payload.organisationId}`,
        { email: payload.email },
        config
      )
      .then(() => {
        this.$axios
          .get(
            `${process.env.AND_DINE_API}/api/users/organisations/${payload.organisationId}`,
            config
          )
          .then((response) => {
            commit("SET_CHOSEN_ORGANISATION", response.data)
          })
      })
      .catch((error) => {
        commit("SET_ORGANISATION_ERROR", error.response.data)
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  removeOrgMember({ commit, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/organisations/removeCustomer/${payload.organisationId}`,
        { email: payload.email },
        config
      )
      .then(() => {
        this.$axios
          .get(
            `${process.env.AND_DINE_API}/api/users/organisations/${payload.organisationId}`,
            config
          )
          .then((response) => {
            commit("SET_CHOSEN_ORGANISATION", response.data)
          })
      })
      .catch((error) => {
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  updateOrganisation({ commit, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/organisations/${payload.organisationId}`,
        payload,
        config
      )
      .then(() => {
        this.$axios
          .get(
            `${process.env.AND_DINE_API}/api/users/organisations/${payload.organisationId}`,
            config
          )
          .then((response) => {
            commit("SET_CHOSEN_ORGANISATION", response.data)
            commit("SET_SUCCESS_MESSAGE", "Succesfully updated organisation")
            setTimeout(() => {
              commit("SET_SUCCESS_MESSAGE", false)
            }, 5000)
          })
      })
      .catch((error) => {
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
        commit(
          "SET_ERROR_MESSAGE",
          `Failed to update subscription - ${error.response.data}`
        )
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
      })
  },
  createOrganisation({ state, dispatch }) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    this.$axios
      .post(
        `${process.env.AND_DINE_API}/api/users/organisations`,
        {
          ...state.newOrganisation,
          is_active: true,
          is_creditable: false,
          credit_limit: 0,
        },
        config
      )
      .then((response) => {
        this.$router.push(`/organisations/${response.data.id}`)
      })
      .catch((error) => {
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  updateCustomerRole({ commit, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }

    this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/organisations/${payload.organisationId}/updateRole`,
        payload,
        config
      )
      .then(() => {
        this.$axios
          .get(
            `${process.env.AND_DINE_API}/api/users/organisations/${payload.organisationId}`,
            config
          )
          .then((response) => {
            commit("SET_CHOSEN_ORGANISATION", response.data)
          })
      })
      .catch((error) => {
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  createTeam({ commit, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    this.$axios
      .post(
        `${process.env.AND_DINE_API}/api/users/organisations/${payload.organisationId}/organisationTeams`,
        payload,
        config
      )
      .then(() => {
        commit("SET_CREATE_TEAM_ERROR", false)
        this.$axios
          .get(
            `${process.env.AND_DINE_API}/api/users/organisations/${payload.organisationId}`,
            config
          )
          .then((response) => {
            commit("SET_CHOSEN_ORGANISATION", response.data)
          })
      })
      .catch((error) => {
        commit(
          "SET_CREATE_TEAM_ERROR",
          "There was an error creating your team. If this error persists please reach out to support."
        )
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  updateTeam({ commit, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/organisations/${payload.organisationId}/organisationTeams/${payload.teamId}`,
        payload,
        config
      )
      .then(() => {
        commit("SET_UPDATE_TEAM_ERROR", false)
        this.$axios
          .get(
            `${process.env.AND_DINE_API}/api/users/organisations/${payload.organisationId}`,
            config
          )
          .then((response) => {
            commit("SET_CHOSEN_ORGANISATION", response.data)
          })
      })
      .catch((error) => {
        commit(
          "SET_UPDATE_TEAM_ERROR",
          "There was an error updating your team. If this error persists please reach out to support."
        )
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  removeCustomerFromTeam({ commit, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    commit("SET_TEAM_LOADING", true)
    this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/organisations/${payload.organisationId}/organisationTeams/${payload.teamId}/removeCustomer`,
        payload,
        config
      )
      .then(() => {
        commit("SET_REMOVE_FROM_TEAM_ERROR", false)
        this.$axios
          .get(
            `${process.env.AND_DINE_API}/api/users/organisations/${payload.organisationId}`,
            config
          )
          .then((response) => {
            commit("SET_TEAM_LOADING", false)
            commit("SET_CHOSEN_ORGANISATION", response.data)
          })
      })
      .catch((error) => {
        commit("SET_TEAM_LOADING", false)
        commit(
          "SET_REMOVE_FROM_TEAM_ERROR",
          "There was an error removing this team member. If this error persists please reach out to support."
        )
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  async resendMakerContract({ commit, state }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }

    commit("SET_RESEND_CONTRACT_LOADING", true)
    try {
      await this.$axios.post(
        `${process.env.AND_DINE_API}/api/users/makers/${state.chosenMaker.id}/resendContract`,
        payload,
        config
      )
      commit("SET_RESEND_CONTRACT_ERROR", false)
      commit("SET_RESEND_CONTRACT_LOADING", false)
    } catch (error) {
      commit("SET_RESEND_CONTRACT_LOADING", false)
      commit("SET_RESEND_CONTRACT_ERROR", error)
      this.$sentry.captureException(error)
    }
  },
  downloadInvoice({ dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/pdf",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/octet-stream; charset=utf-8",
      },
    }

    this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/users/orders/downloadInvoice/${payload.orderId}`,
        config
      )
      .then((res) => {
        function base64ToArrayBuffer(data) {
          const binaryString = window.atob(data)
          const binaryLen = binaryString.length
          const bytes = new Uint8Array(binaryLen)
          for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i)
            bytes[i] = ascii
          }
          return bytes
        }
        const arrayBuffer = base64ToArrayBuffer(res.data)
        const url = window.URL.createObjectURL(
          new Blob([arrayBuffer], {
            type: "application/pdf",
          })
        )
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `&Dine Invoice - ${payload.orderRef}.pdf`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => {
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  downloadMakerReport({ commit }, payload) {
    commit("SET_MAKER_REPORT_LOADING", true)
    const config = {
      headers: {
        accept: "application/pdf",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/octet-stream; charset=utf-8",
      },
    }

    this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/users/orders/makerReport?from=${payload.from}&to=${payload.to}&maker_id=${payload.maker_id}`,
        config
      )
      .then((response) => {
        console.log(response)
        commit("SET_MAKER_REPORT", response.data)
        commit("SET_MAKER_REPORT_LOADING", false)
      })
      .catch((error) => {
        commit("SET_MAKER_REPORT_LOADING", false)
        this.$sentry.captureException(error)
      })
  },
  downloadCSV({ dispatch }, payload) {
    const config = {
      headers: {
        accept: "text/csv",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "text/csv; charset=utf-8",
      },
    }

    this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/users/orders/${payload.request}?from=${payload.from}&to=${payload.to}`,
        config
      )
      .then((res) => {
        const href = URL.createObjectURL(new Blob([res.data]))

        // create "a" HTML element with href to file & click
        const link = document.createElement("a")
        link.href = href
        link.setAttribute(
          "download",
          `${payload.request}_${this.$moment(payload.from).format(
            "DD-MM-YYYY"
          )}_${this.$moment(payload.to).format("DD-MM-YYYY")}.csv`
        ) //or any other extension
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      })
      .catch((error) => {
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
  async regenerateInvoice({ commit }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }

    commit("SET_REGENERATE_INVOICE_LOADING", true)
    try {
      await this.$axios.put(
        `${process.env.AND_DINE_API}/api/users/orders/invoice/${payload.orderId}`,
        {},
        config
      )

      commit("SET_REGENERATE_INVOICE_ERROR", false)
      commit("SET_REGENERATE_INVOICE_LOADING", false)
    } catch (error) {
      commit("SET_REGENERATE_INVOICE_LOADING", false)
      commit("SET_REGENERATE_INVOICE_ERROR", error)
      this.$sentry.captureException(error)
    }
  },
  async updateOrderProducts({ commit, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const { orderId, items } = payload

    commit("SET_UPDATE_PRODUCTS_LOADING", true)
    try {
      await this.$axios.put(
        `${process.env.AND_DINE_API}/api/users/orders/updateProducts/${orderId}`,
        { items },
        config
      )

      commit("SET_UPDATE_PRODUCTS_ERROR", false)
      await this.$axios
        .get(`${process.env.AND_DINE_API}/api/users/orders/${orderId}`, config)
        .then((response) => {
          commit("SET_UPDATE_PRODUCTS_LOADING", false)
          commit("SET_CHOSEN_ORDER", response.data)
        })
    } catch (error) {
      commit("SET_UPDATE_PRODUCTS_LOADING", false)
      commit(
        "SET_UPDATE_PRODUCTS_ERROR",
        "There was an error updating products for this order. If this error persists please reach out to support."
      )
      error.response?.data === "Invalid Token" && dispatch("customerLogout")
      this.$sentry.captureException(error)
    }
  },
  async updateOrderPackage({ commit, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const { orderId, items, packages } = payload

    commit("SET_UPDATE_PRODUCTS_LOADING", true)
    try {
      await this.$axios.put(
        `${process.env.AND_DINE_API}/api/users/orders/updatePackage/${orderId}`,
        { items, packages },
        config
      )

      commit("SET_UPDATE_PRODUCTS_ERROR", false)
      await this.$axios
        .get(`${process.env.AND_DINE_API}/api/users/orders/${orderId}`, config)
        .then((response) => {
          commit("SET_UPDATE_PRODUCTS_LOADING", false)
          commit("SET_CHOSEN_ORDER", response.data)
        })
    } catch (error) {
      commit("SET_UPDATE_PRODUCTS_LOADING", false)
      commit(
        "SET_UPDATE_PRODUCTS_ERROR",
        "There was an error updating products for this order. If this error persists please reach out to support."
      )
      error.response?.data === "Invalid Token" && dispatch("customerLogout")
      this.$sentry.captureException(error)
    }
  },
  async getModifierGroups({ commit }, payload) {
    const filters = payload?.filters || []
    const filterQuery = generateFilterQuery(filters)
    const page = payload?.page || 1
    const limit = payload?.limit || 20
    const query = `?limit=${limit}&page=${page}${filterQuery}`
    !payload && commit("SET_MODIFIER_GROUPS_LOADING", true)
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    await this.$axios
      .get(
        `${process.env.AND_DINE_API}/api/users/modifierGroups${query}`,
        config
      )
      .then((response) => {
        commit("SET_MODIFIER_GROUPS", response.data.modifierGroups)
        commit("SET_PAGES", response.data.paginate)
        commit("SET_MODIFIER_GROUPS_LOADING", false)
      })
      .catch((error) => {
        commit("SET_MODIFIER_GROUPS_LOADING", false)
        console.log({ error }, error.response)
      })
  },
  async updateOrderFees({ commit, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }

    const { orderId, orderFees } = payload

    commit("SET_UPDATE_FEES_LOADING", true)
    try {
      await this.$axios.put(
        `${process.env.AND_DINE_API}/api/users/orders/updateFees/${orderId}`,
        { orderFees },
        config
      )

      commit("SET_UPDATE_FEES_ERROR", false)
      await this.$axios
        .get(`${process.env.AND_DINE_API}/api/users/orders/${orderId}`, config)
        .then((response) => {
          commit("SET_UPDATE_FEES_LOADING", false)
          commit("SET_CHOSEN_ORDER", response.data)
        })
    } catch (error) {
      commit("SET_UPDATE_FEES_LOADING", false)
      commit(
        "SET_UPDATE_FEES_ERROR",
        "There was an error updating fees for this order. If this error persists please reach out to support."
      )
      error.response?.data === "Invalid Token" && dispatch("customerLogout")
      this.$sentry.captureException(error)
    }
  },
  async updateOrderPaymentStatus({ commit, dispatch }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }
    const { orderId, paymentDate } = payload
    try {
      await this.$axios.put(
        `${process.env.AND_DINE_API}/api/users/orders/setPaid/${orderId}`,
        { paymentDate },
        config
      )

      const response = await this.$axios.get(
        `${process.env.AND_DINE_API}/api/users/orders/${orderId}`,
        config
      )

      commit("SET_CHOSEN_ORDER", response.data)

      commit("SET_SUCCESS_MESSAGE", "Successfully updated order")
      setTimeout(() => {
        commit("SET_SUCCESS_MESSAGE", false)
      }, 5000)
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        `Failed to update order - ${error.response.data}`
      )
      setTimeout(() => {
        commit("SET_ERROR_MESSAGE", false)
      }, 5000)
      error.response?.data === "Invalid Token" && dispatch("customerLogout")
      this.$sentry.captureException(error)
    }
  },
  updateSubscription({ dispatch, commit }, payload) {
    const config = {
      headers: {
        accept: "application/json",
        "x-access-token-user": this.$cookies.get("auth_token"),
        "content-type": "application/json",
      },
    }

    commit("SET_SUBSCRIPTION_LOADING", true)
    this.$axios
      .put(
        `${process.env.AND_DINE_API}/api/users/organisations/${payload.organisationId}/subscription`,
        payload.subscription,
        config
      )
      .then(() => {
        this.$axios
          .get(
            `${process.env.AND_DINE_API}/api/users/organisations/${payload.organisationId}`,
            config
          )
          .then((response) => {
            commit("SET_SUBSCRIPTION_LOADING", false)
            commit("SET_SUCCESS_MESSAGE", "Succesfully updated subscription")
            setTimeout(() => {
              commit("SET_SUCCESS_MESSAGE", false)
            }, 5000)
            commit("SET_CHOSEN_ORGANISATION", response.data)
          })
      })
      .catch((error) => {
        commit("SET_SUBSCRIPTION_LOADING", false)
        commit("SET_ERROR_MESSAGE", "Failed to update subscription")
        setTimeout(() => {
          commit("SET_ERROR_MESSAGE", false)
        }, 5000)
        error.response?.data === "Invalid Token" && dispatch("customerLogout")
        this.$sentry.captureException(error)
      })
  },
}

export const getters = {
  makerOptions: (state) => {
    return state.makers.map((maker) => ({
      name: maker.name,
      value: maker.id,
    }))
  },
  cutOffOptions: () => {
    return [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ].map((val) => ({ name: val, value: val }))
  },
  dayBeforeOptions: () => {
    return [1, 2, 3, 4, 5, 6, 7].map((val) => ({ name: val, value: val }))
  },
  userOptions: (state) => {
    return state.users.map((user) => ({
      name: user.email,
      value: user.id,
    }))
  },
  statusOptions: () => {
    return [
      "Created",
      "Cancelled",
      "Accepted",
      "Preparing",
      "Collected",
      "Delivered",
    ].map((status) => ({
      value: status,
      name: status,
    }))
  },
  productTypeOptions: () => {
    return ["Inventory", "Bundle", "Daily"].map((status) => ({
      value: status,
      name: status,
    }))
  },
  discountTypeOptions: () => {
    return ["Percent", "Regular"].map((type) => ({
      value: type.toLowerCase(),
      name: type,
    }))
  },
  customerRolesOptions: () => {
    return ["customer", "sales", "employee", "manager", "owner"].map(
      (type) => ({
        value: type.toLowerCase(),
        name: type,
      })
    )
  },
  discountUsageOptions: () => {
    return ["Auto", "Regular"].map((usage) => ({
      value: usage.toLowerCase(),
      name: usage,
    }))
  },
  orderCount: (state) => {
    return state.orders?.length
  },
  orderCountMonth: (state) => {
    const month = this.$moment().format("M")
    return state.orders?.filter(
      (order) => this.$moment(order.order_date).format("M") === month
    ).length
  },
  orderCountByMaker: (state) => {
    const makerIds = state.user.makers.map((maker) => maker.id)
    return state.orders?.filter((order) => makerIds.includes(order.maker_id))
      .length
  },
  orderCountByMakerMonth: (state) => {
    const makerIds = state.user.makers.map((maker) => maker.id)
    const month = this.$moment().format("M")
    return state.orders?.filter(
      (order) =>
        makerIds.includes(order.maker_id) &&
        this.$moment(order.order_date).format("M") === month
    ).length
  },
}

const generateFilterQuery = (filters, date = undefined) => {
  let filterQuery = ""

  if (filters.length) {
    filterQuery =
      filterQuery +
      "&filter=" +
      JSON.stringify(
        filters.map(([key, value, exact, not]) => {
          return { key: key, value: value, exact, not }
        })
      )
  }

  if (date) {
    filterQuery = filterQuery + "&date=" + JSON.stringify(date)
  }
  return filterQuery
}
