
import { mapState } from "vuex"

export default {
  name: "SideBar",
  props: {
    navItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    ...mapState(["isAdmin"]),
    hasItems() {
      return this.navItems.length > 0
    },
    selectedRoute() {
      return this.$route.path
    },
  },
  methods: {
    toggleSideBar() {
      this.isOpen = !this.isOpen
      this.$emit("isOpen", this.isOpen)
    },
  },
}
