
import { mapActions, mapState } from "vuex"

export default {
  name: "NavBar",
  data: () => ({
    showUserDrop: false,
    s3Image: process.env.S3_IMAGE,
  }),
  computed: {
    ...mapState(["user", "isAdmin"]),
  },
  methods: {
    ...mapActions(["logoutUser"]),
    openUser() {
      this.showUserDrop = !this.showUserDrop
    },
  },
}
